import cx from 'classnames';
import { DialogContent, DialogOverlay } from 'components/@tedui/Dialog/Dialog';
import { useDarkMode } from 'components/theme/DarkModeProvider';
import type { HTMLAttributes } from 'react';

import NavigationModalProps, { NavigationModalType } from './Modal.props';

function NavigationModal({
  type = NavigationModalType.MenuNavigation,
  children,
  isOpen,
  onDismiss,
  ariaLabel,
  navigation
}: NavigationModalProps & HTMLAttributes<HTMLElement>): React.ReactNode {
  const isMenuNavigation = type === NavigationModalType.MenuNavigation;
  const isUserNavigation = type === NavigationModalType.UserNavigation;
  const { isDarkMode } = useDarkMode();

  const overlayClasses = cx(
    'fixed inset-0 z-40 flex h-full animate-fadeIn bg-black bg-opacity-20 dark:bg-gray-800 lg:hidden',
    {
      dark: isDarkMode,
      'items-end': !isUserNavigation,
      'items-start': isUserNavigation
    }
  );

  const renderModal = () => {
    const modalClasses = cx(
      'fixed top-14 min-h-drawer w-full overflow-y-auto bg-white dark:bg-gray-800 md:w-1/2 md:px-10',
      {
        'bottom-0 left-0 animate-slideToRightModal px-5 pb-6 md:pb-8':
          isMenuNavigation,
        'right-0 h-auto animate-slideDownModal px-10 pb-8 md:bottom-0 md:animate-slideToLeftModal':
          isUserNavigation
      }
    );

    return (
      <DialogContent
        className={modalClasses}
        aria-label={ariaLabel}
        data-testid="main-navigation__menu-drawer"
      >
        {children}
      </DialogContent>
    );
  };

  return (
    <DialogOverlay
      className={overlayClasses}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      {navigation}
      {renderModal()}
    </DialogOverlay>
  );
}

export default NavigationModal;
