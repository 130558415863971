export enum NavigationModalType {
  MenuNavigation = 'MENU NAVIGATION',
  UserNavigation = 'USER NAVIGATION'
}

type NavigationModalProps = {
  onDismiss: () => void;
  ariaLabel: string;
  children: React.ReactNode;
  navigation?: React.ReactNode;
  isOpen: boolean;
  type?: NavigationModalType;
};

export default NavigationModalProps;
