import { BaseButtonType } from 'components/base-button';
import Divider from 'components/divider';
import LinkButton from 'components/link-button';
import { Link } from 'components/router';
import SearchLarge from 'components/search/search-large';
import { Microcopy, Text } from 'components/typography';
import mixpanel from 'lib/analytics/mixpanel';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';
import {
  PAGE_TYPES,
  membershipLink,
  myMembershipLink,
  topNavigationMenus
} from './data';
import NavigationModal from './Modal';
import { NavigationModalType } from './Modal/Modal.props';

type MenuModalProps = {
  navigationModalOpen: boolean;
  setNavigationModalOpen: (open: boolean) => void;
  userIsMember: boolean;
  handleSubmit: () => void;
  inputValue: string;
  setInputValue: (v: string) => void;
};

const MenuModal = ({
  navigationModalOpen,
  setNavigationModalOpen,
  userIsMember,
  handleSubmit,
  inputValue,
  setInputValue
}: MenuModalProps): React.ReactNode => {
  const [, setNavigationContext] = useLocalStorage('navigation_context', '');

  const getMembershipLabel = (size?: 'uppercase') => {
    return size === 'uppercase'
      ? membershipLink.fundRaiseUpUpperCaseLabel
      : membershipLink.fundRaiseUpLabel;
  };

  return (
    <NavigationModal
      isOpen={navigationModalOpen}
      onDismiss={() => setNavigationModalOpen(false)}
      ariaLabel="Menu"
      type={NavigationModalType.MenuNavigation}
    >
      <div className="mt-8 flex">
        <SearchLarge
          placeholder="Search talks, events, authors..."
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(event.target.value)
          }
          onSubmit={handleSubmit}
          value={inputValue}
        />
      </div>
      <nav>
        <ul>
          {topNavigationMenus.map((menu, index) => (
            <li key={menu.mixpanelContext}>
              <div className="pb-3 pt-8">
                <Microcopy
                  size="xs"
                  className="mb-6 font-bold text-gray-500 dark:text-[#CFCFD0]"
                >
                  {menu.label}
                </Microcopy>
                <ul className="column-count-2 md:column-count-auto">
                  {menu.submenus.map(submenu => (
                    <li key={submenu.link} className="mb-5 inline-block w-full">
                      {submenu.pageType === PAGE_TYPES.ZENITH ||
                      submenu.pageType === PAGE_TYPES.PRISMIC ? (
                        <Link
                          className="block hover:underline"
                          href={submenu.link}
                          onClick={() => {
                            setNavigationContext(
                              `Header:${menu.mixpanelContext}:${submenu.label.props?.defaultMessage[0]?.value}`
                            );
                            mixpanel.track('global_nav_click', {
                              system_language: navigator.language || null,
                              event_interaction_context: submenu.mixpanelContext
                            });
                          }}
                        >
                          <Text size="m" className="font-bold dark:text-white">
                            {submenu.label}
                          </Text>
                        </Link>
                      ) : (
                        <a
                          className="block hover:underline"
                          href={submenu.link}
                          onClick={() => {
                            setNavigationContext(
                              `Header:${menu.mixpanelContext}:${submenu.label.props?.defaultMessage[0]?.value}`
                            );
                            mixpanel.track('global_nav_click', {
                              system_language: navigator.language || null,
                              event_interaction_context: submenu.mixpanelContext
                            });
                          }}
                        >
                          <Text size="m" className="font-bold dark:text-white">
                            {submenu.label}
                          </Text>
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {index + 1 < topNavigationMenus.length && <Divider />}
            </li>
          ))}
        </ul>
      </nav>
      <LinkButton
        href={userIsMember ? myMembershipLink.link : membershipLink.link}
        text={userIsMember ? myMembershipLink.label : getMembershipLabel()}
        onClick={() => {
          mixpanel.track('global_nav_click', {
            system_language: navigator.language || null,
            event_interaction_context: userIsMember
              ? myMembershipLink.mixpanelContext
              : membershipLink.mixpanelContext
          });
        }}
        variant={
          userIsMember ? BaseButtonType.Tertiary : BaseButtonType.Primary
        }
        fullWidth
        className="mt-6 block"
      />
    </NavigationModal>
  );
};

export default MenuModal;
