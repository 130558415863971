import cx from 'classnames';
import { ChangeEvent, HTMLAttributes, useRef } from 'react';

import { getRandomString } from 'lib/util';

import SearchIcon from 'icons/SearchIcon';

type SearchProps = {
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
  partOfDefaultSearch?: boolean;
};

export default function SearchLarge({
  id,
  placeholder,
  disabled,
  className,
  value,
  onChange,
  onSubmit,
  partOfDefaultSearch
}: SearchProps & HTMLAttributes<HTMLInputElement>): React.ReactNode {
  const idRef = useRef(getRandomString());
  const inputId = `${id || idRef.current}-large`;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  const wrapperClasses = cx(`relative h-12 w-full ${className}`, {
    'opacity-50': disabled,
    'hidden md:block': partOfDefaultSearch
  });

  return (
    <form onSubmit={e => handleSubmit(e)} className={wrapperClasses}>
      <div className="pointer-events-none absolute left-3 top-3 h-6 w-6 p-05">
        <SearchIcon className="size-full dark:text-white" />
      </div>
      <div className="h-12 w-full">
        <label htmlFor={inputId} className="sr-only">
          {placeholder}
        </label>
        <input
          type="search"
          id={inputId}
          value={value}
          placeholder={placeholder}
          aria-label={placeholder}
          onChange={onChange}
          className="size-full rounded-sm border-thin border-black border-opacity-16 bg-transparent py-3 pl-12 pr-3 text-base outline-none transition-colors hover:border-gray-500 hover:border-opacity-100 focus:border-gray-500 focus:border-opacity-100 dark:bg-black dark:text-white lg:leading-md"
          disabled={disabled}
        />
      </div>
    </form>
  );
}
